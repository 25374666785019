body {
    overflow-x: hidden !important;
    position: relative;
}

.spinnerH {
    height: 90%;
}

.logo {
    width: 200px;
}

.flag-dropdown {
    position: relative;
    display: inline-block;
    margin: 1rem 0;
    width: 170px;
    color: white;
}

.selected-item {
    cursor: pointer;
    display: flex;
    width: 170px;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: black;
    border: none;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: black;
    border: 0;
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-list li {
    cursor: pointer;
    display: flex;
    width: 170px;
    align-items: center;
    padding: 0.5rem 1rem;
}

.dropdown-list li img {
    width: 20px;
    height: 14px;
    margin-right: 0.5rem;
}

.selectedCountry {
    text-transform:capitalize;
}